
import { Component, Vue } from 'vue-property-decorator';
import { inject } from '@/inversify';
// @ts-ignore
import CustomSelect, { Item } from '@/modules/common/components/ui-kit/custom-select.vue';
import ClusterService, { ClusterServiceS } from '@/modules/cluster/cluster.service';
import ProvidersService, { ProvidersServiceS } from '@/modules/providers/providers.service';

@Component({
    components: { CustomSelect },
})
export default class RatesClusterProviderTypeFilter extends Vue {
    @inject(ClusterServiceS) private clusterService!: ClusterService;
    @inject(ProvidersServiceS) private providersService!: ProvidersService;

    get value() {
        if (this.$route.name?.includes('markets') || this.$route.name?.includes('ranking')) {
            return this.clusterService.currentMarketsProvider;
        }
        return this.clusterService.currentRatesProvider;
    }

    set value(value) {
        this.clusterService.saveCurrentProvider(value);
        this.$emit('change', value);
    }

    get items(): Item[] {
        const { chainRatesProviders } = this.providersService;

        return this.providersService.toItemsList(chainRatesProviders);
    }
}
