
import { Component, Vue } from 'vue-property-decorator';
import { inject } from '@/inversify';
// @ts-ignore
import CustomSelect, { Item } from '@/modules/common/components/ui-kit/custom-select.vue';
import ClusterRatesService, { ClusterRatesServiceS } from '@/modules/cluster/cluster-rates.service';
import MealTypesService, { MealTypesServiceS } from '@/modules/meal-types/meal-types.service';
import RatesFiltersService, { RatesFiltersServiceS } from '@/modules/rates/rates-filters.service';
import ANY_MEAL_TYPE from '@/modules/meal-types/constants/any-meal-type.constant';

@Component({
    components: { CustomSelect },
})
export default class RatesClusterRoomTypeFilter extends Vue {
    @inject(ClusterRatesServiceS)
    public clusterRatesService!: ClusterRatesService;

    @inject(MealTypesServiceS)
    public mealTypesService!: MealTypesService;

    @inject(RatesFiltersServiceS)
    public ratesFiltersService!: RatesFiltersService;

    get value() {
        if (this.clusterRatesService.storeState.settings.mealTypeId === null) {
            this.mealTypesService.storeState.loading.whenLoadingFinished()
                .then(() => {
                    this.value = this.mealTypesService.getMealType(null)!.id;
                });
            return ANY_MEAL_TYPE.id;
        }
        return this.clusterRatesService.storeState.settings.mealTypeId;
    }

    set value(value: number | null) {
        this.clusterRatesService.storeState.settings.mealTypeId = value;
        this.ratesFiltersService.settings.mealTypeId = value;
    }

    get items(): Item[] {
        return this.mealTypesService.mealTypes
            .map(meal => ({
                value: meal.id,
                name: this.$tc(meal.displayName),
            }));
    }
}
