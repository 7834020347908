var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{class:{
        'toggle-prices': true,
        'toggle-prices--disabled': _vm.isDisabled,
    }},[_c('span',{staticClass:"toggle-prices__label"},[_vm._v(_vm._s(_vm.$t('titles.price'))+":")]),_c('ul',{staticClass:"toggle-prices__list"},_vm._l((_vm.items),function(option){return _c('li',{key:option.name,class:{
                'toggle-prices__item': true,
                'toggle-prices__item--active': option.value === _vm.model,
            }},[_c('button',{domProps:{"innerHTML":_vm._s(_vm.$t(option.name))},on:{"click":function($event){_vm.model = option.value}}})])}),0)])
}
var staticRenderFns = []

export { render, staticRenderFns }