
import { Component, Vue, Prop } from 'vue-property-decorator';
import CustomDropdown from '@/modules/common/components/ui-kit/custom-dropdown.vue';

@Component({
    components: {
        CustomDropdown,
    },
})
export default class RatesExcelBtn extends Vue {
    @Prop({
        required: false,
        type: Boolean,
        default: false,
    })
    disable!: boolean;

    get items() {
        return [
            {
                link: 'download-excel',
                text: this.$tc('download'),
            },
            {
                link: 'scheduled-reports',
                text: this.$tc('schedule'),
            },
        ];
    }
}
