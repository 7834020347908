
import { Component, Vue } from 'vue-property-decorator';
import { inject } from '@/inversify';
// @ts-ignore
import CustomSelect, { Item } from '@/modules/common/components/ui-kit/custom-select.vue';
import DocumentFiltersService, { DocumentFiltersServiceS } from '@/modules/document-filters/document-filters.service';
import DEFAULT_LOS from '@/modules/document-filters/constants/default-los.constant';

@Component({
    components: { CustomSelect },
})
export default class RatesClusterLosFilter extends Vue {
    @inject(DocumentFiltersServiceS)
    private documentFilterService!: DocumentFiltersService;

    get value(): number {
        return this.documentFilterService.storeState.settings.los!;
    }

    set value(value: number) {
        this.documentFilterService.storeState.settings.los = value;
    }

    get items(): Item[] {
        return DEFAULT_LOS.map(los => ({
            value: los,
            name: this.$t('filters.los.num', [los]).toString(),
        }));
    }
}
