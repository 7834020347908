
import { Component, Vue } from 'vue-property-decorator';
import { inject } from '@/inversify';
// @ts-ignore
import CustomSelect, { Item } from '@/modules/common/components/ui-kit/custom-select.vue';
import ClusterRatesService, { ClusterRatesServiceS } from '@/modules/cluster/cluster-rates.service';
import PRICE_TYPE from '@/modules/document-filters/constants/price-type.constant';
import RatesFiltersService, { RatesFiltersServiceS } from '@/modules/rates/rates-filters.service';

@Component({
    components: { CustomSelect },
})
export default class RatesClusterPriceFilter extends Vue {
    @inject(ClusterRatesServiceS)
    private clusterRatesService!: ClusterRatesService;

    @inject(RatesFiltersServiceS)
    private ratesFiltersService!: RatesFiltersService;

    get value() {
        return this.clusterRatesService.priceType;
    }

    set value(value: PRICE_TYPE) {
        this.clusterRatesService.priceType = value;
        this.ratesFiltersService.priceType = value;
    }

    get items(): Item[] {
        return Object
            .values(PRICE_TYPE)
            .map(priceType => ({
                value: priceType,
                name: this.$tc(`price.${priceType}`),
            }));
    }
}
